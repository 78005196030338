<template>
  <div>
    <div class="propety">
      <div style="min-height: 600px">
        <el-form :model="data" ref="ListingForm" :rules="rules">
          <div class="pages">
            <div class="form_in">
              <!-- General Information  -->
              <div>
                <div class="top-prodect" @click="generalShow = !generalShow">
                  <span class="title-text">General Information</span><i class="el-icon-arrow-down"
                    v-if="generalShow"></i>
                  <i class="el-icon-arrow-right" v-if="!generalShow"></i>
                </div>
                <div v-if="generalShow">
                  <div class="set_width" style="">
                    <div class="propety_row" style="margin-botton: 20px">
                      <div class="propety_type">
                        <el-form-item prop="key_number">
                          <p class="propety-title">Key Number</p>
                          <el-input v-model="data.key_number" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">House Phone Number</p>
                          <el-input v-model="data.house_phone" type="tel" maxlength="22"
                            @input="(e) => (data.house_phone = changePhone(e))" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">
                            MA Occupancy Tax Certificate Number
                          </p>
                          <el-input v-model="data.occupancy_tax_number" placeholder
                            oninput="value=value.replace(/[\W]/g,'')"></el-input>
                        </el-form-item>
                        <el-form-item prop="year_built">
                          <p class="propety-title">Year Built</p>
                          <el-input v-model="data.year_built" placeholder type="number" maxlength="4"></el-input>
                        </el-form-item>
                        <el-form-item prop="year_renovated">
                          <p class="propety-title">Year Renovated</p>
                          <el-input v-model="data.year_renovated" placeholder type="number" maxlength="4">
                          </el-input>
                        </el-form-item>
                        <el-form-item prop="virtual_tour_link">
                          <p class="property-title">Virtual Tour Link</p>
                          <el-input v-model="data.virtual_tour_link" placeholder type="url" maxlength="255">
                          </el-input>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- House Rules -->
              <div>
                <div class="top-prodect" @click="houserulesShow = !houserulesShow">
                  <span class="title-text">House Rules</span><i class="el-icon-arrow-down" v-if="houserulesShow"></i>
                  <i class="el-icon-arrow-right" v-if="!houserulesShow"></i>
                </div>
                <div v-if="houserulesShow">
                  <div class="set_width" style="">
                    <div class="propety_row" style="margin-botton: 20px">
                      <div class="propety_type" v-if="data && data.requirement">
                        <el-form-item prop="capacity" :rules="rules.required">
                          <p class="propety-title">Max Sleeping Capacity</p>
                          <el-select v-model="data.capacity" placeholder="Please choose">
                            <el-option v-for="item in 100" :key="item" :label="item" :value="item"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Pets</p>
                          <el-select v-model="data.requirement.pet_allow" placeholder="Please choose">
                            <el-option v-for="(item, index) in pets" :key="index" :label="item.label" :value="item.value" ></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item v-show="showPetFee">
                          <p class="propety-title">Pet Fee Amount</p>
                          <el-input  v-model="data.requirement.pet_fee" type="number"/>
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Turnover Day</p>
                          <el-input v-model="data.requirement.turnover_day" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Minimum Night Stay</p>
                          <el-input v-model="data.requirement.min_night_stay" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Security Deposit %</p>
                          <el-input v-model="data.requirement.security_deposit_percentage" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Cleaning Hours Included</p>
                          <el-input v-model="data.requirement.cleaning_hours" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Security Deposit Minimum</p>
                          <el-input v-model="data.requirement.min_security_deposit" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Cleaning Fee</p>
                          <el-input v-model="data.requirement.cleaning_fee" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Check-in Time</p>
                          <el-input v-model="data.requirement.checkin_time" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Check-out Time</p>
                          <el-input v-model="data.requirement.checkout_time" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Payment(# of)</p>
                          <el-input v-model="data.requirement.payment_number" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Commission %</p>
                          <el-input v-model="data.requirement.commission_percentage" />
                        </el-form-item>
                        <el-form-item>
                          <p class="propety-title">Charge Community Impact Fee</p>
                          <el-select v-model="data.requirement.charge_community_impact_fee" placeholder="Please choose">
                            <el-option v-for="(item, index) in communityImpactFeeOptions" :key="index" :label="item.label" :value="item.value" ></el-option>
                          </el-select>
                        </el-form-item>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--Publishing & Distribution  -->
              <div>
                <div class="top-prodect" @click="publishingShow = !publishingShow">
                  <span class="title-text">Publishing & Distribution</span><i class="el-icon-arrow-down"
                    v-if="publishingShow"></i>
                  <i class="el-icon-arrow-right" v-if="!publishingShow"></i>
                </div>
                <div v-if="publishingShow">
                  <div class="set_width" style="">
                    <div class="check-row row">
                      <div class="sg_cate" v-if="$route.params.id">
                        <el-radio-group v-model="action" @change="selectAction">
                          <el-radio label="publish">PUBLISH</el-radio>
                          <el-radio label="hide">HIDE FROM PUBLIC</el-radio>
                          <el-radio label="deactivate">DEACTIVATE</el-radio>
                        </el-radio-group>
                      </div>
                      <div class="text-reason" v-show="showReason">
                        <p>Text reason for deactivation</p>
                        <el-input v-model="deactivated_reason" />
                      </div>
                    </div>
                    <div class="propety_row" style="margin-botton: 20px">
                      <div class="propety_type">
                        <el-form-item>
                          <p class="propety-title">Listing Priority</p>
                          <el-select v-model="data.priority" placeholder="Please choose">
                            <el-option v-for="item in propertyPriorityList" :key="item.id" :label="item.name"
                              :value="item.id"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item prop="other_rental_firms">
                          <p class="propety-title">Other Rental Firms</p>
                          <el-select v-model="data.other_rental_firms" placeholder="Please choose" multiple filterable
                            default-first-option>
                            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form>
      </div>
      <div class="btn-save">
        <el-button class="primary-button" @click="save" v-loading.fullscreen.lock="fullscreenLoading">
          <p class="row btn-row">
            <span>Save/Update</span><i class="el-icon-right"></i>
          </p>
        </el-button>
      </div>
    </div>
    <!-- 保存的按钮 -->
    <div class="propety_save" @click="save" v-if="btnFlag">
      <el-tooltip class="item" effect="dark" content="Save" placement="bottom">
        <img src="../../assets/img/save.png" alt />
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { apiGetListing, apiUpdateListing, apiPublishListing, apiHideListing, apiDeactivateListing } from "../../API/api";
import { Minixs } from "../../js/mixins";
export default {
  components: {},
  mixins: [Minixs],
  data() {
    return {
      loadingFile: false,
      generalShow: true,
      houserulesShow: true,
      publishingShow: true,
      showReason: false,
      deactivated_reason: "",
      fullscreenLoading: false,
      btnFlag: true,
      fileName: "Attached Files", // 文件名
      fileUrl: "", // 文件访问地址
      action: "hide",
      map: {}, // 用来存放房间和浴室的对应关系
      data: {},
      pets: [
        {
          value: 'true',
          label: "Yes",
        },
        {
          value: 'false',
          label: "No",
        },
        {
          value: 'ask_owner',
          label: "Ask Owner",
        },
        // {
        //   value: "considered",
        //   label: "Considered",
        // },
      ],
      communityImpactFeeOptions: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        }
      ],
      showPet: false,
      rules: {
        required: [
          {
            required: true,
            message: "this information is required",
            trigger: ["blur", "change"],
          },
        ],
      },
      availabilities: [],
    };
  },
  methods: {
    ...mapActions([
      "getPropertyPriorityList",
      "getCompanyList",

    ]),
    selectAction(val) {
      if (val === "deactivate") {
        this.showReason = true;
      } else {
        this.showReason = false;
      }
    },
    // 修改Action动作
    changeAction(res) {
      if (res.deleted_at) {
        this.action = "deactivate";
        this.deactivated_reason = res.deactivated_reason;
        this.showReason = true;
      } else if (res.publish) {
        this.action = "publish";
        this.showReason = false;
      } else {
        this.action = "hide";
        this.showReason = false;
      }
    },
    // 数据格式转换
    changeDate(val) {
      let res = Object.assign({}, val)
      // 转换枚举类型
      if (res.occupancy_tax_file) {
        this.fileName = res.occupancy_tax_file.file_name;
        this.fileUrl = res.occupancy_tax_file.url;
        res.occupancy_tax_file = res.occupancy_tax_file.occupancy_tax_file_uuid;
      }
      if (res.priority) res.priority = res.priority.id;
      if (res.area) res.area = res.area.id;
      if (res.ac_types) {
        let arr = []
        res.ac_types.forEach(item => {
          arr.push(item.id)
        });
        res.ac_types = arr
      };
      if (res.heating_type) res.heating_type = res.heating_type.id;
      if (res.tv_service) res.tv_service = res.tv_service.id;
      if (res.coffee_maker_type)
        res.coffee_maker_type = res.coffee_maker_type.id;
      if (res.stove_type) res.stove_type = res.stove_type.id;
      if (res.toaster_type) res.toaster_type = res.toaster_type.id;
      if (res.grill_type) res.grill_type = res.grill_type.id;
      if (res.pool_type) res.pool_type = res.pool_type.id;
      if (res.bedrooms.length === 0) {
        for (let i = 0; i < res.bedroom_number; i++) {
          res.bedrooms.push({
            floor_level: "",
            en_suite: false,
            beds: [{ type: "", number: 0 }],
          });
        }
      } else {
        for (let i = 0; i < res.bedrooms.length; i++) {
          res.bedrooms[i].floor_level = res.bedrooms[i].floor_level.id;
          let beds = res.bedrooms[i].beds;
          for (let j = 0; j < beds.length; j++) {
            res.bedrooms[i].beds[j].type = beds[j].type.id;
          }
        }
      }

      for (let i = 0; i < res.bathrooms.length; i++) {
        res.bathrooms[i].type = res.bathrooms[i].type.id;
      }
      // 关联浴室和房间
      if (res.bathrooms.length === 0) {
        for (let i = 0; i < res.bathroom_number; i++) {
          res.bathrooms.push({
            index: "", // 关联的房间
            checked: false, // 是否是房间的私有浴室
            bidet: false,
            combination_tub: false,
            toilet: false,
            tub: false,
            shower: false,
            type: "",
          });
        }
      } else {
        for (let i = 0; i < res.bathrooms.length; i++) {
          if (res.bathrooms[i].bedroom) {
            res.bathrooms[i]["checked"] = true;
            res.bedrooms.forEach((bedroom, index) => {
              if (res.bathrooms[i].bedroom === bedroom.bedroom_uuid) {
                res.bathrooms[i]["index"] = index;
                this.map[i] = index;
              }
            });
          } else {
            res.bathrooms[i]["checked"] = false;
            res.bathrooms[i]["index"] = "";
          }
        }
      }

      // 删除不能传空值的key
      delete res.homeowner;
      // 删除该页面不会用到的key
      delete res.images;
      delete res.availabilities;
      delete res.rates;
      return res;
    },
    getListing() {
      apiGetListing(this.$route.params.id).then((res) => {
        this.availabilities = res;
        setTimeout(() => {
          if (this.$refs.retaWeek) this.$refs.retaWeek.setRatesList()
        }, 3000);
        this.$store.commit("setListing", JSON.parse(JSON.stringify(res)));
        this.$store.commit("setPropertyInfor", true);
        this.$store.commit("setPropertyReuirement", true);
        if (res.availabilities.length != 0 || res.rates.length != 0) {
          this.$store.commit("setPropertyCalendar", true);
        }
        if (res.images.length != 0) {
          this.$store.commit("setPropertyPhoto", true);
        }
        this.data = this.changeDate(res);
        this.changeAction(res);
      });
    },

    save() {
      this.$refs.ListingForm.validate((valid) => {
        if (valid) {
          let listing = JSON.parse(JSON.stringify(this.data));

          this.fullscreenLoading = true;
          if (this.$route.params.id) {
            // 修改
            apiUpdateListing(this.$route.params.id, listing)
              .then(
                (res) => {
                  this.$store.commit(
                    "setListing",
                    JSON.parse(JSON.stringify(res))
                  );

                  this.$store.commit("setPropertyInfor", true);
                  if (res.availabilities.length != 0 || res.rates.length != 0) {
                    this.$store.commit("setPropertyCalendar", true);
                  }
                  if (res.images.length != 0) {
                    this.$store.commit("setPropertyPhoto", true);
                  }
                  this.data = this.changeDate(res);
                  if (this.action === "publish" && !res.publish) {
                    // 未发布才发布
                    return apiPublishListing(this.$route.params.id).then(
                      (res) => {
                        this.$router.push({
                          name: "property-general",
                          params: { id: res.listing_id },
                        });
                        this.$message.success("Listing publish successfully");
                      }
                    );
                  } else if (
                    this.action === "deactivate" &&
                    (!res.deleted_at ||
                      res.deactivated_reason != this.deactivated_reason)
                  ) {
                    // 未禁用才禁用
                    return apiDeactivateListing(res.listing_id, {
                      deactivated_reason: this.deactivated_reason,
                    }).then((res) => {
                      this.$router.push({
                        name: "property-general",
                        params: { id: res.listing_id },
                      });
                      this.$message.success("Listing deactivate successfully");
                    });
                  } else if (
                    this.action === "hide" &&
                    (res.publish || res.deleted_at)
                  ) {
                    // 发布或禁用才变为隐藏
                    return apiHideListing(res.listing_id).then((res) => {
                      this.$message.success("Listing hide successfully");
                      this.$router.push({
                        name: "property-general",
                        params: { id: res.listing_id },
                      });
                    });
                  } else {
                    this.$router.push({
                      name: "property-general",
                      params: { id: res.listing_id },
                    });
                    this.$message.success(
                      "Listing information successfully modified"
                    );
                  }
                },
                (err) => {
                  this.$message.error("Listing information modify fail");
                }
              )
              .catch((err) => {
                this.$message.error(err);
              }).finally(() => {
                this.fullscreenLoading = false;
              });
          }
        } else {
          setTimeout(() => {
            let isError = document.getElementsByClassName("is-error");
            let input = isError[0].querySelector("input");
            if (input) {
              input.focus();
            } else {
              isError[0].querySelector("textarea").focus();
            }
          }, 100);
        }
      });
    },
    changeStatus(selector) {
      var anchor = this.$el.querySelector(selector); // 参数为要跳转到的元素id
      document.body.scrollTop = anchor.offsetTop; // chrome
      document.documentElement.scrollTop = anchor.offsetTop; // firefox
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      let scrollHeight = window.pageYOffset || document.body.scrollHeight;
      let height = document.documentElement.scrollHeight;
      if (scrollHeight > height - 1000) {
        this.btnFlag = false;
      } else {
        if (this.$route.params.id) {
          this.btnFlag = true;
        } else {
          this.btnFlag = false;
        }
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  computed: {
    ...mapState({
        propertyPriorityList: (state) => state.enum.propertyPriorityList,
        companyList: (state) => state.enum.companyList,
    }),
    showPetFee() {
      return this.data.requirement?.pet_allow == 'true' || this.data.requirement?.pet_allow == true;
    }
  },
  created() {

    if (this.propertyPriorityList.length === 0) {
      this.getPropertyPriorityList();
    }
    if (this.companyList.length === 0) {
      this.getCompanyList();
    }

    if (this.$route.params.id) {
      // if (this.$store.state.listing.listing_id === this.$route.params.id - 0) {
      //   this.data = this.changeDate(
      //     JSON.parse(JSON.stringify(this.$store.state.listing))
      //   );
      //   this.changeAction(this.data);
      // } else {
      this.getListing();
      // }
    }
  },
};
</script>
<style lang="scss" scoped>
@import "../../css/button.scss";
@import "./Property.scss";

.sg_cate {
  padding: 10px 0px 10px 0px !important;
}
</style>
